.container-gr-req{
    border: 2px solid rgb(11, 190, 190);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    width: 80%;
    height: 100%;
    background-color: rgb(208, 239, 245);
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}


.container-r-list-item-header-info{
    background-color: white;
    padding: 20px;
    display: flex;
    border-bottom:solid 1px grey;
    width: 100%;
    font-size: 35px;
}

.label-req{
    font-weight: 600;
    margin-top: 10px;
}

.label-req-title{
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 50px;
    font-size: 20px;

}

.r-list-item-details-clave-v{
    margin-top: 5px;
    font-weight: 600;
    font-size: 20px;
    margin-right: 50px;
    background-color: rgb(214, 214, 214);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 3px 3px 0px;
}

.r-list-item-details-title-v{
    font-weight: 600;
    margin-top: 5px;
    background-color: rgb(214, 214, 214);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 3px 3px 0px;
}


.r-list-item-details-data-v{
    margin-left: 20px;
}

.r-list-item-details-fecha-v{
    display: flex;
    width: 100%;
    justify-content: right;
    margin-right: 20px;
}

.container-r-item-details{
    width: 100%;
    font-size: 20px;
    padding: 20px;
}

.container-r-item-title-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.container-r-info-p{
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    padding: 10px;
}

.container-r-fecha{
    background-color:  white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
}

.label-req-fecha{
    display: flex;
    padding: 3px;
}

.container-r-texto{
    background-color: white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    padding: 10px;
    height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}



.list-item-icon-r{
    display: flex;
    justify-content: center;
    color: rgb(3, 212, 212);
    font-size: 40px;
    font-style: normal;
    font-weight: normal;
    margin-top: 15px;
    width: 100%;
}

.list-item-title-r{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;

}

.container-list-products-r-gral{
    background-color:  white;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

}

.container-list-products-r{
    display: flex;
}

.container-list-product-r-icon{
    justify-content: center;
    width: 15%;
    /*margin-left: 20px;
    margin-bottom: 20px;*/
}

.container-list-products-r-details{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
}

.container-cantidad-product-r{
    border-top:solid 1px grey;
    margin-top: 10px;
}

.rq-container-buttons-bottom{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.rq-button-bottom{
    margin: 0 auto;
    padding: 15px;
    background-color: rgba(0, 72, 139, 0.842);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    color: white;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 25px;
}

.rq-button-bottom:hover{
    cursor: pointer;
    transform: scale(1.04);
}


.container-files-rq{
    display: flex;
    justify-content: center;
    width: 100%;
}

.container-files-rq-details{
    background-color:  white;
    width: 100%;
    height: 100px;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 5px 5px 0px;
    border-radius: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.container-files-rq-details-items{
    display: flex;
    justify-content: center;
    padding: 7px;
    border-bottom:solid 1px grey;
    color: blue;
    cursor: pointer;
}

.container-button-download{
    margin-bottom: 10px;
}

.button-download{
    margin: 0 auto;
    padding: 10px;
    background-color: rgba(0, 72, 139, 0.842);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 3px 3px 0px;
    color: white;
    box-sizing: border-box;
    border-radius: 7px;
    font-size: 25px;
    border: 0px;
}

.button-edit{
    padding: 10px;
    background-color: rgba(255, 159, 6, 0.699);
    box-shadow: rgba(148, 142, 142, 0.59) 4px 3px 3px 0px;
    color: white;
    box-sizing: border-box;
    border-radius: 7px;
    font-size: 20px;
    border: 0px;
    margin-right: 10px;
}


.r-textarea-input{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    height: auto;
    width: 99%;
    background-color: white;
}

.options-container-reqinfo{
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    margin-right: 10px;
    padding: 10px;
    background-color: rgba(160, 160, 160, 0.486);
    border-radius: 10px;
}
