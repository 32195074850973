.container-row-form-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    font-size: 16px;
  }
  
  .container-row-form-item input, 
  .container-row-form-item .input-autocomplete-form-item
  {
    padding: 10px;
    border: none;
  }

