.container-gr-state{
    border: 2px solid rgb(11, 190, 190);
    box-sizing: border-box;
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
    height: 260px;
    width: 500px;
    background-color: white;
}

.container-state{
}

.label-state{
    margin-top: 20px;
}

.label-state-data{
    display: flex;
    justify-content: center;
    font-size: 20px;
    border-bottom:solid 1px grey;
    padding: 10px;
}

.label-state-icon-r{
    display: flex;
    justify-content: right; /* Alinear elementos al centro horizontalmente */
    align-items: right; /* Alinear elementos al centro verticalmente */
    font-size: 30px;
    width: 100%;
    height: 30px;
    cursor: pointer;
}

.state-icon-r{
    margin-right: 10px;
}

.container-state-list{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

}

.btn-state-container{
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-state{
    width:200px; 
    color: white;
    font-size: 18px;
    border:none;
    background-color:rgb(248, 176, 61);
    border-radius:5px;
    text-align: center;
    padding: 10px 5px;
    cursor: pointer;
  }
  
  .btn-state:hover{
    box-shadow: rgba(148, 142, 142, 0.59) 4px 7px 5px 0px;
  }

  .StateChange-div-item-check{
    display: flex;
    justify-content: center; /* Alinear elementos al centro horizontalmente */
    align-items: center; /* Alinear elementos al centro verticalmente */
    font-size: 15px;
    margin-bottom: 15px;
}

  .StateChange-list-item-check{
    margin-left: 18px;
    margin-right: 5px;
    align-items: center;
    width:17px;
    height:20px;
    font-size: 14px;
}